.footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    height: 4.5rem;
    z-index: 200;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px -12px 64px rgba(29, 58, 88, 0.04);
    backdrop-filter: blur(10px);
    border-radius: 12px 12px 0px 0px;
}

.active .label {
    color: #1A51A3;
    font-weight: 800;
}

.label {
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    text-align: center;
    color: #8991AC;
    line-height: 14px;
    font-family: 'Avenir';
}


@media only screen and (min-width: 769px) {
    .footer {
        display: none !important;
    }
}