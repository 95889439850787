
.ratingDialog {
    padding: 2rem 1.5rem 1.25rem !important;
}

.ratingTitle {
    font-size: 1.25rem;
    font-weight: bold;
    margin: 0;
}

.customerName {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: center;
    margin: 1.5rem 0 0.5rem;
}

.info {
    font-size: 1rem;
    font-weight: 400;
    text-align: center;
    color: var(--accent-light);
    width: 90%;
    margin: 0rem auto 1.5rem;
}

.role {
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    margin: 0rem auto 0.5rem;
}

.rating {
    margin: 0 auto 1.25rem;
}

.actionButton {
  margin: 1.5rem 0 !important;
  padding: 0.875rem 0 !important;
  font-weight: 500 !important;
  width: 100% !important;
}
