.label {
    width: max-content;
    font-weight: 500;
    font-size: 0.75rem;
    color: var(--accent-light);
    margin-bottom: 0.6rem;
}

.label > sup {
    color: red;
    margin-left: 0.25rem;
}
