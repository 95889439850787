@font-face {
    font-family: "Gilmer";
    src: url("/fonts/gilmer/gilmer-light.otf") format("opentype");
    font-display: swap;
    font-weight: 300;
}

@font-face {
    font-family: "Gilmer";
    src: url("/fonts/gilmer/Gilmer-Regular.otf") format("opentype");
    font-display: swap;
    font-weight: 400;
}

@font-face {
    font-family: "Gilmer";
    src: url("/fonts/gilmer/Gilmer-Medium.otf") format("opentype");
    font-display: swap;
    font-weight: 500;
}

@font-face {
    font-family: "Gilmer";
    src: url("/fonts/gilmer/Gilmer-Bold.otf") format("opentype");
    font-display: swap;
    font-weight: 700;
}

@font-face {
    font-family: "Gilmer";
    src: url("/fonts/gilmer/Gilmer-Heavy.otf") format("opentype");
    font-display: swap;
    font-weight: 900;
}

:root {
    --primary: #00559e;
    --alerzo-blue: #1A51A3;
    --disabled-text : #8991AC;
    --headline-text:  #434343;
    --accent: #000000;
    --accent-medium: #3e4659;
    --accent-light: #69778c;
    --disabled-background:#F3F3F7;
    --background: #FEFEFF;
    --desktop-background: #F9FAFC;
    --border: #858585;
    --border-medium: #cccfd9;
    --border-light: #dce0eb;
    --success: #00954B;
    --danger: #ec4747;
    --danger-light: rgba(235, 87, 87, 0.2);;
    --warn: #f9aa3e;
}

html {
    height: 100vh;
    width: 100vw;
    font-size: 16px;
}

/* purgecss ignore */
body, #__next {
    /* Enable scroll to top by disabling the next two properties */
    /* height: 100%;
    width: 100%; */
    margin: 0;
    padding: 0;
    font-family: 'Gilmer', Roboto, Helvetica, Arial, sans-serif;
    background-color: var(--background);
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    margin: 0 !important; 
}

@media only screen and (max-width: 768px) {
    .large-screen {
        display: none !important;
    }
}

@media only screen and (min-width: 769px) {
    .small-screen {
        display: none !important;
    }
}

