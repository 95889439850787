.mainDialog {
  padding: 1rem;
}

.modalHeader {
  padding: 0.7rem 1rem !important;
  font-size: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.modalTitle {
  margin: 0.5rem 0;
}

.modalCloseButton {
  float: right;
  cursor: pointer;
}

.modalAction {
  width: 100%;
  color: #fff !important;
  background: #00559e !important;
  padding: 1rem !important;
  border-radius: 6px !important;
}

.modalBody {
  font-size: 15px;
  line-height: 22px;
  padding: 1rem 1.5rem !important;
}
