.buttonContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  padding: 40px;
  height: 100%;
}

.declineButton {
  color: #1A51A3 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  width: 122px !important;
  height: 60px !important;
  border: 1px solid #1A51A3 !important;
  box-shadow: 0px 1px 2px 0px #1018280D !important;
  border-radius: 10px !important;
  background-color: white !important;
  margin: 0 !important;
}

.acceptButton:hover {
  filter: brightness(110%);
  transition: all 0.5s !important;
}

.textContainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.title {
  margin: 0;
  font-weight: 900;
  font-size: 18px;
  color: #434343
}

.description {
  color: #69778C;
  font-weight: 400;
  font-size: 16px;
}

.link {
  color: #1A51A3;
  font-weight: 800;
  cursor: pointer;
}