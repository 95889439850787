.container {
  margin: 0 1rem;
  padding: 0.75rem 1rem;
  background-color: black;
  color: white;
  border-radius: 0.4rem;
  position: fixed;
  bottom: 1.5rem;
  left: 20;
  right: 20;
  z-index: 12000 !important;
}

.containerError {
  background-color: rgb(206, 49, 49) !important;
}

.padBottom {
  bottom: 5rem !important;
}

.cartItems {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.cartTotal {
  margin-top: 0.25rem;
  line-height: 1rem;
}

.btnLabel {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.icon {
  margin-left: 0.5rem;
}

@media only screen and (min-width: 769px) {
  .topCenter {
    margin: 0;
    bottom: unset;
    top: 1.5rem;
    width: 400px;
    left: calc(50% - 200px - 1rem);
  }
}
