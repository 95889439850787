.textarea {
    width: 100%;
    box-sizing: border-box;
    resize: none;
    font-family: inherit;
    padding: 0.5rem;
    border-radius: 0.25rem;
    border: 1px solid var(--border-medium);
}

.textarea:focus {
    outline: none !important;
}

.helperText {
    font-size: 0.75rem;
    color: var(--accent-light);
    margin: 0;
    text-align: right;
    position: absolute;
    bottom: 0.5rem;
    right: 0.5rem;
    background-color: white;
}

.error {
    margin-top: 0.25rem;
}
