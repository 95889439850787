.button {
    text-transform: unset !important;
    line-height: normal;
}

.button:disabled {
    background-color: var(--primary) !important;
    opacity: 0.5;
    color: white !important;
}
