/* purgecss start ignore */
.MuiSelect-outlined.MuiSelect-outlined {
  padding: 0.75em 0.5em !important;
}

.MuiTab-root.MuiButtonBase-root {
  min-width: 130px;
}

.MuiInputBase-root .MuiInputAdornment-positionStart {
  margin-right: 0.75rem !important;
}

.MuiTab-root span.MuiTab-wrapper {
  text-transform: none;
  font-size: 0.875rem !important;
  font-weight: normal !important;
}

.no-shadow {
  box-shadow: none !important;
}

.full-width-tabs .MuiButtonBase-root {
  flex: 1;
}

.MuiButtonBase-root .MuiButton-label {
  line-height: 1;
}

.MuiDrawer-paperAnchorBottom {
  border-radius: 1.25em 1.25em 0 0;
}

.fullscreen .MuiDialog-paper {
  border-radius: 0 !important;
  margin: 0 !important;
  width: 100% !important;
  height: 100% !important;
  min-height: 100%;
  min-width: 100%;
}

.MuiDialog-paper {
  margin: 1em !important;
  width: calc(100% - 1em) !important;
}

.MuiTableCell-root {
  border-bottom: none !important;
}

.MuiAccordion-root.Mui-expanded {
  margin-top: 0;
}

.custom-pagination .MuiTablePagination-toolbar {
  width: 100%;
}

.custom-pagination .MuiTablePagination-selectRoot {
  border: 1px solid var(--header) !important;
  border-radius: 0.25rem !important;
  padding-bottom: 0 !important;
}

.custom-pagination .MuiTablePagination-spacer {
  display: none;
}

.custom-pagination .MuiTablePagination-toolbar > .MuiTablePagination-caption {
  flex: 1;
}
/* purgecss end ignore */

body * {
  /* This is the magic bit for Firefox */
  scrollbar-width: none;
}

body *::-webkit-scrollbar {
  /* This is the magic bit for WebKit */
  display: none;
}
