.row {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.j-start {
  justify-content: flex-start;
}

.j-end {
  justify-content: flex-end;
}

.j-space-between {
  justify-content: space-between;
}

.j-space-around {
  justify-content: space-around;
}

.j-center {
  justify-content: center;
}

.a-stretch {
  align-items: stretch;
}

.a-start {
  align-items: flex-start;
}

.a-end {
  align-items: flex-end;
}

.a-center {
  align-items: center;
}

.wrap {
  flex-wrap: wrap;
}

.flex-1 {
  flex: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.scroll-x {
  overflow-x: scroll;
}

.scroll-y {
  overflow-y: scroll;
}

.h-100 {
  height: 100%;
}

.w-100 {
  width: 100%;
}

.relative {
  position: relative;
}

.pad-bottom {
  padding-bottom: 3.5em;
}

.m-auto{
  margin: auto;
}

/* purgecss start ignore */
.superscript {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
}

.grid-border {
  border-top: 1px solid var(--border-light);
  border-left: 1px solid var(--border-light);
}

.grid-border-alt > .grid-item-alt {
  border-left: 1px solid var(--border-light) !important;
}

.grid-border-alt > .grid-item-alt ~ .grid-item-alt {
  border-left: none !important;
}

.grid-item-border {
  border: none !important;
  border-bottom: 1px solid var(--border-light) !important;
  border-right: 1px solid var(--border-light) !important;
}

.row-start-center {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

/* purgecss end ignore */

.progress{
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top:0px;
  z-index: 100000000000000;
}

.card-size{
  min-height: 17rem;
  min-width: unset !important;
}

.card-size-desktop{
  min-height: 17rem;
}

.maskedOverflowY {
  --scrollbar-width: 1px;
  --mask-height: 16px;
  padding-bottom:5px;
  padding-right: 0.5em;
  --mask-image-content: linear-gradient(
    to bottom,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
    );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}

.maskedOverflowX {
  --scrollbar-width: 0px;
  --mask-height: 5px;
  padding-left: 0.5em;
  --mask-image-content: linear-gradient(
    to right,
    transparent,
    black var(--mask-height),
    black calc(100% - var(--mask-height)),
    transparent
  );
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;
  --mask-image-scrollbar: linear-gradient(black, black);
  --mask-size-scrollbar: var(--scrollbar-width) 100%;
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);
  mask-position: 0 0, 100% 0;
  mask-repeat: no-repeat, no-repeat;
}