.container {
  margin: 0 1rem;
  box-sizing: border-box;
  padding: 0.75rem 1rem;
  background-color: black;
  color: white;
  border-radius: 0.4rem;
  position: fixed;
  bottom: 1.5rem;
  left: 0;
  right: 0;
  z-index: 2000;
}

.padBottom {
  bottom: 5rem !important;
}

.cartItems {
  font-size: 0.875rem;
  line-height: 0.875rem;
}

.cartTotal {
  margin-top: 0.25rem;
  line-height: 1rem;
}

.btnLabel {
  font-size: 1rem;
  font-weight: 500;
  color: white;
  text-transform: capitalize;
  cursor: default;
}

.icon {
  margin-left: 0.5rem;
}

@media only screen and (min-width: 769px) {
  .container {
    width: 400px;
    left: calc(50% - 200px);
    margin: 0;
  }
}
